body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scroll-behavior: smooth;
}

.logo-title {
    text-transform: uppercase;
    letter-spacing: .25rem;
}

.section-title {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .25rem;
}

.pedigree-name {
    position: absolute;
    z-index: 12;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 .75rem;
}

.pedigree-name:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 11;
}

.pedigree-name div {
    z-index: 13;
}

.pedigree-photo-1 {
    max-height: calc(60% + 1rem);
}

.pedigree-photo-2 {
    max-height: calc(60% + 1rem);
}

.pedigree-photo-1 img,
.pedigree-photo-2 img {
    margin-top: 1rem;
    border-radius: .5rem;
}

 /* Karballido Staffs Mambo Golden Legend */
.m-1::before {
    background-image: url(https://www.pedigreedatabase.com/pictures/2376359-789988.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    opacity: .3;
}

/* Karballido Staffs Queen Of Hearts */
.f-1::before {
    background-image: url(https://cdn-5.pedigreedatabase.com/pictures/3059602-1020319.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .3;
}

/* Karballido Staffs Pomerland Secret */
.m-1-m-2::before {
    background-image: url(https://cdn-1.pedigreedatabase.com/pictures/2168997-585633.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    opacity: .3;
}

/* Citadela King Of Ring's */
.m-1-f-2::before {
    background-image: url(https://www.pedigreedatabase.com/pictures/1857755-537969.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    opacity: .3;
}

/* Alpine's LBK Living On The Road */
.f-1-m-2::before {
    background-image: url(https://www.pedigreedatabase.com/pictures/2591118-831006.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .3;
}

/* Karballido Staffs Electra Natchios */
.f-1-f-2::before {
    background-image: url(https://cdn-2.pedigreedatabase.com/pictures/1963527-529463.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    opacity: .3;
}

/* Cuda's Playboy */
.m-1-m-2-m-3::before {
    background-image: url(https://www.pedigreedatabase.com/pictures/1324056-125516.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -20px;
    opacity: .3;
}

/* Or Starlight "Red Mist" King Of Ring's */
.m-1-m-2-f-3::before {
    background-image: url('https://');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    opacity: .3;
}

/* Don King Of Ring's */
.m-1-f-2-m-3::before {
    background-image: url('https://www.pedigreedatabase.com/pictures/2459568-692778.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -65px;
    opacity: .3;
}

/* Francesca Of Eagle Blend */
.m-1-f-2-f-3::before {
    background-image: url('https://www.pedigreedatabase.com/pictures/1017456-64281.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -65px;
    opacity: .3;
}

/* Sbigstaff Enuf Is Enuf For LBK */
.f-1-m-2-m-3::before {
    background-image: url('https://www.pedigreedatabase.com/pictures/964721-877023.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -45px;
    opacity: .3;
}

/* Alpine's Isn't She Lovely */
.f-1-m-2-f-3::before {
    background-image: url('https://www.pedigreedatabase.com/pictures/1981332-588327.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -50px;
    opacity: .3;
}

/* Builder's Best Heavenly Rider Long Step */
.f-1-f-2-m-3::before {
    background-image: url('https://www.pedigreedatabase.com/pictures/818553-43733.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -70px;
    opacity: .3;
}

/* Karballido Staffs Quite Easy */
.f-1-f-2-f-3::before {
    background-image: url('https://www.pedigreedatabase.com/pictures/1962459-1034320.jpg');
    background-size: 130%;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -95px;
    opacity: .3;
}

.vertical-text {
    writing-mode: vertical-lr;
    text-orientation: upright;
    font-size: 17rem;
    line-height: 1.1;
    font-weight: 900;
    letter-spacing: -5rem;
    opacity: .03;
}
